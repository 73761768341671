import * as React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import {  Label, Input, FormGroup, NavLink } from 'reactstrap';
import '../../css/CreateAccount.css';
import { ValidationTooltip } from '../validation_tooltip/ValidationTooltip'
import PasswordStrengthBar from 'react-password-strength-bar';
import { PasswordMatch } from '../password_match/PasswordMatch';
import ReCAPTCHA from "react-google-recaptcha";
import { authUrls } from '../../constants';

interface IProps {
    
}

interface IState {
    firstName: string,
    firstNametooltipOpen: boolean,
    lastName: string,
    lastNametooltipOpen: boolean,
    email: string,
    emailTooltipOpen: boolean,
    emailTooltipMessage: string,
    password1: string,
    password1TooltipOpen: boolean,
    password1Message: string,
    password2: string,
    password2TooltipOpen: boolean,
    password2Message: string,
    passwordScore: number,
    acceptTos: boolean,
    acceptTosTooltipOpen: boolean,
    captchaValue: string,
    captchaTooltipOpen: boolean,
    creating: boolean
}

export class CreateAccount extends React.Component<IProps, IState> {
    static displayName = CreateAccount.name;

    constructor(props: IProps) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPassword1Change = this.onPassword1Change.bind(this);
        this.onPassword2Change = this.onPassword2Change.bind(this);
        this.onAcceptTosChanged = this.onAcceptTosChanged.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this);
        this.onChangeScore = this.onChangeScore.bind(this);

        this.state = {         
            firstName: "",
            firstNametooltipOpen: false,
            lastName: "",
            lastNametooltipOpen: false,
            email: "",
            emailTooltipOpen: false,
            emailTooltipMessage: "",
            password1: "",
            password1TooltipOpen: false,
            password1Message: "",
            password2: "",
            password2TooltipOpen: false,
            password2Message: "",
            passwordScore: 0,
            acceptTos: false,
            acceptTosTooltipOpen: false,
            captchaValue: "",
            captchaTooltipOpen: false,
            creating: false
        };
    }

    recaptchaRef: any;

    getCookie(name: string) {
        let cookieValue = "";
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();

                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }

        return cookieValue;
    }

    handleSubmit(e: any) {
        e.preventDefault();

        let error = false;

        if (this.state.firstName === "") {
            this.setState({
                firstNametooltipOpen: true,
            });
            
            error = true;
        }

        if (this.state.lastName === "") {
            this.setState({
                lastNametooltipOpen: true,
            });

            error = true;
        }

        if (this.state.email === "") {
            this.setState({
                emailTooltipMessage: "Please fill in this field.",
                emailTooltipOpen: true,
            });

            error = true;
        }

        if (this.state.passwordScore <= 1) {
            this.setState({
                password1Message: "Password not strong enough",
                password1TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.password1 === "") {
            this.setState({
                password1Message: "Please fill in this field.",
                password1TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.password2 === "") {
            this.setState({
                password2Message: "Please fill in this field.",
                password2TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.password1 !== this.state.password2) {
            this.setState({
                password2Message: "Passwords do not match.",
                password2TooltipOpen: true,
            });

            error = true;
        }

        if (!this.state.acceptTos) {
            this.setState({
                acceptTosTooltipOpen: true,
            });

            error = true;
        }

        //if (this.state.captchaValue == "") {
        //    this.setState({
        //        captchaTooltipOpen: true,
        //    });

        //    error = true;
        //}
        
        if (error) {
            return;
        }

        this.setState({ creating: true });

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let returnUrl = params.get('ReturnUrl');
        
        const data = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email: this.state.email,
            Password: this.state.password1,
            ConfirmPassword: this.state.password2,
            AcceptedTos: this.state.acceptTos,
            CaptchaKey: this.state.captchaValue,
            ReturnUrl: returnUrl
        };

        let xsrfToken = this.getCookie('XSRF-TOKEN');   //*get AntiforgeryToken from Cookies
        if (xsrfToken == null) { alert("AntiforgeryToken is missing") };

        fetch(authUrls.createAccount, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': "application/json, text/plain, */*",
                'Content-Type': "application/json;charset=utf-8",
                'X-XSRF-TOKEN': xsrfToken
            }
        }).then(response => response.json())
            .then(data => {
                if (!data["success"]) {
                    this.setState({
                        captchaValue: "",
                        creating: false
                    });

                    switch (data["errorCode"]) {
                        case 0:
                            this.setState({
                                emailTooltipMessage: "A user with this email address already exists",
                                emailTooltipOpen: true,
                            });

                            break;
                        case 1:
                            this.setState({
                                password2Message: "Passwords do not match.",
                                password2TooltipOpen: true,
                            });

                            break;
                        case 2:
                            this.setState({
                                password1Message: "Password not strong enough",
                                password1TooltipOpen: true,
                            });

                            break;
                        default:

                            break;
                    }
                    console.log(data);
                    
                    this.recaptchaRef.reset();
                }
                else {
                    const redirectUrl: string = data["result"]["RedirectUrl"];
                    window.location.href = redirectUrl;
                }
            });
    }

    onFirstNameChange(e: any) {
        this.setState({
            firstName: e.target.value,
            firstNametooltipOpen: false,
        });
    }

    onLastNameChange(e: any) {
        this.setState({
            lastName: e.target.value,
            lastNametooltipOpen: false,
        });
    }

    onEmailChange(e: any) {
        this.setState({
            email: e.target.value,
            emailTooltipOpen: false,
        });
    }

    onPassword1Change(e: any) {
        this.setState({
            password1: e.target.value,
            password1TooltipOpen: false,
        });
    }

    onPassword2Change(e: any) {
        this.setState({
            password2: e.target.value,
            password2TooltipOpen: false,
        });
    }

    onAcceptTosChanged(e: any) {
        this.setState({
            acceptTos: e.target.checked,
            acceptTosTooltipOpen: false
        });
    }

    onCaptchaChange(value: any) {
        this.setState({
            captchaValue: value,
            captchaTooltipOpen: false
        });
    }

    onCaptchaExpired() {
        this.setState({
            captchaValue: ""
        });
    }

    onChangeScore(score: number) {
        this.setState({
            passwordScore: score
        });
    }

    renderSignin() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Enter your details</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{ size: 6, offset: 3 }}>
                            <div id="eform">
                                <form onSubmit={this.handleSubmit} noValidate>
                                    <Row>
                                        <Col md="6" ms="12">
                                            <label>First Name</label>
                                            <input name="firstName" type="text" className="form-control" id="firstName" onChange={this.onFirstNameChange} />
                                            <ValidationTooltip placement="bottom" tooltipOpen={this.state.firstNametooltipOpen} target="firstName" text="Please fill in this field." />
                                        </Col>

                                        <Col md="6" ms="12">
                                            <label>Last Name</label>
                                            <input name="lastName" type="text" className="form-control" id="lastName" onChange={this.onLastNameChange} />
                                            <ValidationTooltip placement="bottom" tooltipOpen={this.state.lastNametooltipOpen} target="lastName" text="Please fill in this field." />

                                        </Col>
                                    </Row>
                                    <label>Email</label>
                                    <input name="email" type="email" className="form-control" id="email" onChange={this.onEmailChange} />
                                    <ValidationTooltip placement="bottom" tooltipOpen={this.state.emailTooltipOpen} target="email" text={this.state.emailTooltipMessage} />

                                    <label className="mt-2">Password</label>
                                    <input name="password" type="password" className="form-control" id="password" onChange={this.onPassword1Change} />
                                    <ValidationTooltip placement="bottom" tooltipOpen={this.state.password1TooltipOpen} target="password" text={this.state.password1Message} />
                                    <PasswordStrengthBar shortScoreWord="Too short" scoreWords={["Too short", "Too weak", "Okay", "Good", "Strong"]} password={this.state.password1} onChangeScore={this.onChangeScore} />
                                    
                                    <label>Confirm Password</label>
                                    <input name="password2" type="password" className="form-control" id="password2" onChange={this.onPassword2Change} />
                                    <ValidationTooltip placement="bottom" tooltipOpen={this.state.password2TooltipOpen} target="password2" text={this.state.password2Message} />
                                    <PasswordMatch password1={this.state.password1} password2={this.state.password2} />

                                    <label>Terms of Service</label>
                                    <FormGroup check className="ml-2 mb-3">
                                        <Label check>
                                            <Input id="acceptTos" type="checkbox" onChange={this.onAcceptTosChanged} />{" "}
                                            &nbsp;I have read and agree to the <NavLink tag={Link} to="/tos" style={{ display: 'inline-block', padding: '0' }}>Terms of Service</NavLink> agreement.
                                        </Label>
                                    </FormGroup>
                                    <ValidationTooltip placement="bottom" tooltipOpen={this.state.acceptTosTooltipOpen} target="acceptTos" text="Please accept the terms of service." />

                                    <Row className="justify-content-md-center">
                                        <Col id="captcha" md="auto">
                                            <ReCAPTCHA ref={(el) => this.recaptchaRef = el} sitekey="6LfSLL0cAAAAADW2Mx9QoiaFcCoYVhNgrKYT244N" onChange={this.onCaptchaChange} onExpired={this.onCaptchaExpired} />
                                            <ValidationTooltip placement="bottom" tooltipOpen={this.state.captchaTooltipOpen} target="captcha" text="Please complete the captcha" />
                                        </Col>
                                    </Row>
                                    <input type="submit" className="form-control" id="submit" value="Create Account" />
                                </form>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Link to="/forgot-password">Forgot your password?</Link>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Link to="/login">Login</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    renderCreating() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Creating Account</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={{ size: 'auto' }}>
                            <Spinner animation="border" role="status">
                                Signing in...
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    render() {
        return this.state.creating ? this.renderCreating() : this.renderSignin();
    }
}