import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import './NavMenu.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import * as AuthStore from '../store/AuthStore';
import { connect } from 'react-redux';
import * as authActionCreators from '../services/AuthService';
import { ApplicationState } from '../store';
import { bindActionCreators } from 'redux';

type AuthProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    ;

class NavMenu extends React.Component<AuthProps, { collapsed: boolean, dropdownOpen: boolean }> {
    static displayName = NavMenu.name;

    constructor(props: AuthProps) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    public state = {
        collapsed: true,
        dropdownOpen: false
    };

    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    renderLoggedIn() {
        return (
            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle nav>
                    <FontAwesomeIcon icon={faUser} />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag={Link} to="/">Account</DropdownItem>
                    {this.renderAdmin()}
                    <DropdownItem tag={Link} to="/logout">Logout</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }

    renderNotLoggedIn() {
        return (
            <NavItem>
                <NavLink tag={Link} to="/login"><FontAwesomeIcon icon={faUser} /></NavLink>
            </NavItem>
        );
    }

    renderAdmin() {
        if (this.props.userRoles.some(r => ["SuperAdmin", "Admin"].includes(r))) {
            return (
                <DropdownItem tag={Link} to="/admin">Admin</DropdownItem>
            );
        }

        return (null);
    }

    public render() {
        return (
            <header>
                <Navbar color="light" className="navbar-default navbar-light navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3">
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img src={require('../images/dd.png')} height="70" alt="" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} to="/privacy-policy">Privacy Policy</NavLink>
                                </NavItem>
                                {this.props.isAuthenticated ? this.renderLoggedIn() : this.renderNotLoggedIn()}
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        stateKnown: state.authStore.stateKnown,
        isAuthenticated: state.authStore.isAuthenticated,
        displayName: state.authStore.displayName,
        userRoles: state.authStore.userRoles
    }
};

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(authActionCreators, dispatch);
};

export default connect(
    mapStateToProps
)(NavMenu);
