import * as React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import * as AuthService from "../../services/AuthService";

interface IState {
    userCount: number,
    lastWeekUserCount: number
}

export class Admin extends React.Component<{}, IState> {
    static displayName = Admin.name;

    constructor(props: {}) {
        super(props);

        this.state = {
            userCount: 0,
            lastWeekUserCount: 0
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        this.getData();
    }


    getData() {
        AuthService.getUserCount(data => this.setState({ userCount: data.totalUsers, lastWeekUserCount: data.newUsersPastWeek }));
    }

    submitTestEmail() {
        AuthService.sendTestEmail();
    }

    render() {
        return (
            <Container className="details">
                <Row>
                    <Col md="12">
                        <h2>Admin</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <Table striped bordered hover variant="dark">
                            <tbody>
                                <tr>
                                    <td>Total Users</td>
                                    <td>{this.state.userCount}</td>
                                </tr>
                                <tr>
                                    <td>New Users this week</td>
                                    <td>{this.state.lastWeekUserCount}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <form onSubmit={this.submitTestEmail} noValidate>
                            <h3>Send Test email</h3>
                            <input type="submit" className="form-control" id="submit" value="Submit" />
                        </form>
                    </Col>
                </Row>
            </Container>
        );
    }
}
