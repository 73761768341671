import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

interface IProps {
    password1: string;
    password2: string;
}

interface IState {
    
}

export class PasswordMatch extends React.Component<IProps, IState> {
    static displayName = PasswordMatch.name;

    constructor(props: IProps) {
        super(props);
    }

    render() {
        const { password1, password2 } = this.props;
        let message = "d";
        let backgroundColor = "#ef4836";
        let opacity = 1;

        if (typeof password1 == 'undefined' || !password1) {
            opacity = 0;
        }
        else if (typeof password2 == 'undefined' || !password2) {
            opacity = 0;
        }
        else if (password1 === password2) {
            message = "Match";
            backgroundColor = "#25c281";
            opacity = 1;
        }
        else {
            message = "Not matching";
            opacity = 1;
        }

        const styleObj: React.CSSProperties = {
            fontSize: 14,
            color: "#898792",
            textAlign: "right",
            margin: '5px 0px 0px',
        }

        return (
            <Container style={{ opacity: opacity }}>
                <Row>
                    <Col md="12" style={{ background: backgroundColor, width: '100%', height: '2px' }}>

                    </Col>
                </Row>
                <Row>
                    <Col md="12" style={{ padding: '0' }}>
                        <p style={styleObj}>{message}</p>
                    </Col>
                </Row>
            </Container>
        );
    }
}