import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { authUrls } from '../../constants/authUrls';
import * as AuthStore from '../../store/AuthStore';
import * as authActionCreators from '../../services/AuthService';

type AuthProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof authActionCreators // ... plus action creators we've requested
    ;

class Logout extends React.PureComponent<AuthProps, { innerText: string, headerText: string }> {
    static displayName = Logout.name;

    constructor(props: any) {
        super(props);

        this.state = {
            headerText: "Logging out",
            innerText: ""
        }

        this.onLoggedOut = this.onLoggedOut.bind(this);
    }

    componentDidMount() {
        this.logMeOut();
    }

    async logMeOut() {
        var query = window.location.search;
        var logoutIdQuery = query && query.toLowerCase().indexOf('?logoutid=') == 0 && query;
        
        const response = await fetch(authUrls.logout + logoutIdQuery , {
            credentials: 'include'
        });

        const data = await response.json();

        if (data.signOutIFrameUrl) {
            const iframe = document.createElement('iframe');
            iframe.width = "0";
            iframe.height = "0";
            iframe.className = 'signout';
            iframe.src = data.signOutIFrameUrl;

            if (document != null) {
                const element = document.getElementById('logout_iframe');
                if (element != null) {
                    element.appendChild(iframe);
                }
            }
        }

        this.props.setLoggedOut(this.onLoggedOut);
        
        if (data.postLogoutRedirectUri) {
            window.location.href = data.postLogoutRedirectUri;
        } else {
            window.location.href = "/";
        }
    }

    onLoggedOut() {
        this.setState({
            headerText: "Logged out",
            innerText: "Logged out. You can now close this window"
        });
    }

    render() {
        return (
            <div>
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Logging out</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <div id="bye">{this.state.innerText}</div>
                        </Col>
                    </Row>
                </Container>
                <div id="logout_iframe"></div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(authActionCreators, dispatch);
};

export default connect(
    null,
    mapDispatchToProps
)(Logout);
