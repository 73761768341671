import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import * as AuthStore from '../../store/AuthStore';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';


type PublicRouteProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof AuthStore.actionCreators // ... plus action creators we've requested
    & RouteProps;

interface IState {
    isAuthenticated: boolean
}

class PublicRoute extends React.PureComponent<PublicRouteProps, IState> {
    static displayName = PublicRoute.name;
    
    constructor(props: PublicRouteProps) {
        super(props);

        this.state = {
            isAuthenticated: this.props.isAuthenticated
        };
    }

    redirectUrl = "/";

    render() {
        if (!this.state.isAuthenticated) {
            return <Route {...this.props} /> ;
        }
        else {
            return <Redirect to={this.redirectUrl} />;
        }
    }
}

export default connect(
    (state: ApplicationState) => state.authStore,
    AuthStore.actionCreators
)(PublicRoute);