import * as React from 'react';
import NavMenu from '../NavMenu';
import { Footer } from "../footer/Footer";

export class Layout extends React.Component {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <header />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
