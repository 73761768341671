import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Table, Button } from 'reactstrap';
import { authUrls } from '../../constants';
import { ApplicationState } from '../../store';
import * as AuthStore from '../../store/AuthStore';
import './Home.css';

interface IState {
    displayName: string,
    loading: boolean,
    email: string,
    emailConfirmed: boolean
}

type AuthProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof AuthStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>;

class Home extends React.PureComponent<AuthProps, IState> {
    constructor(props: AuthProps) {
        super(props);

        this.state = {
            displayName: this.props.displayName,
            loading: true,
            email: '',
            emailConfirmed: false
        };

        this.onDataReceived = this.onDataReceived.bind(this);
        this.sendEmailVerification = this.sendEmailVerification.bind(this);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        fetch(authUrls.userDetailedInfo, {
            method: 'GET',
            headers: {
                'Accept': "application/json, text/plain, */*",
                'Content-Type': "application/json;charset=utf-8",
            }
        }).then(response => response.json())
            .then(data => this.onDataReceived(data));
    }

    onDataReceived(data: any) {
        this.setState({
            email: data.email,
            emailConfirmed: data.emailConfirmed,
            loading: false
        });
    }

    renderEmailVerificationButton() {
        return (
            <Button color="primary" onClick={this.sendEmailVerification}>
                Send Verification Email
            </Button>
        );
    }

    sendEmailVerification() {
        
    }

    renderChangePasswordButton() {
        return (
            <Link to="/change-password">
                <Button color="primary">
                    Update
                </Button>
            </Link>
        );
    }

    renderDeleteAccountButton() {
        return (
            <Link to="/delete-account">
                <Button color="primary">
                    Delete
                </Button>
            </Link>
        );
    }

    render() {
        if (this.state.loading) {
            return "loading";
        }

        return (
            <Container className="details">
                <Row>
                    <Col md="12">
                        <h2 data-wow-offset="50" data-wow-delay="0.3s">Hello {this.state.displayName}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 8, offset: 2 }}>
                        <Table striped bordered hover variant="dark">
                            <tbody>
                                <tr>
                                    <td>Email</td>
                                    <td>{this.state.email}</td>
                                    <td>{this.state.emailConfirmed ? "Verified" : this.renderEmailVerificationButton()}</td>
                                </tr>
                                <tr>
                                    <td>Password</td>
                                    <td>*****************</td>
                                    <td>{this.renderChangePasswordButton()}</td>
                                </tr>
                                <tr>
                                    <td>Account status</td>
                                    <td>Active</td>
                                    <td>{this.renderDeleteAccountButton()}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        displayName: state.authStore.displayName
    }
};

export default connect(mapStateToProps)(Home);
