import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';

export class Footer extends React.Component {
    static displayName = Footer.name;

    render() {
        return (
            <footer id="copyright">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p>Copyright &copy; 2022 dataDyne Laboratories</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        );
    }
}
