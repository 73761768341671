import * as React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { authUrls } from '../../constants';

interface IState {
    confirming: boolean,
    error: string
}

export class ConfirmEmail extends React.Component<{}, IState> {
    static displayName = ConfirmEmail.name;

    constructor() {
        super({});

        this.state = {
            confirming: true,
            error: ""            
        };
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userId = params.get('userId');
        const code = params.get('code');

        const data = {
            UserId: userId,
            Code: code
        };

        fetch(authUrls.confirmEmail, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Accept': "application/json, text/plain, */*",
                'Content-Type': "application/json;charset=utf-8"
            }
        }).then(response => response.json())
            .then(data => {
                if (!data["success"]) {
                    this.setState({
                        confirming: false,
                        error: "Unable to verify email address",
                    });
                }
                else {
                    this.setState({
                        confirming: false,
                        error: '',
                    });
                }
            });
    }

    renderConfirming() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Confirming Email...</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={{ size: 'auto' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Confirming...</span>
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    renderResult() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>{ this.state.error ? "Failure" : "Success!" }</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={{ size: 'auto' }}>
                            {this.state.error ? this.state.error : "Email Confirmed"}
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    render() {
        return this.state.confirming ? this.renderConfirming() : this.renderResult();
    }
}