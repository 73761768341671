import * as React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as AuthStore from '../../store/AuthStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../services/AuthService';
import PasswordStrengthBar from 'react-password-strength-bar';
import { ValidationTooltip } from '../validation_tooltip/ValidationTooltip';
import { PasswordMatch } from '../password_match/PasswordMatch';

interface IState {
    updatingPassword: boolean,
    complete: boolean,
    passwordScore: number,
    password: string,
    passwordConfirm: string,
    password1TooltipOpen: boolean,
    password1Message: string,
    password2TooltipOpen: boolean,
    password2Message: string,
    responseMessage: string
    oldPassword: string,
    oldPasswordTooltipOpen: boolean,
    oldPasswordMessage: string
}

type AuthProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof authActionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>;


class ChangePassword extends React.PureComponent<AuthProps, IState> {
    static displayName = ChangePassword.name;

    constructor(props: AuthProps) {
        super(props);
        this.state = {
            updatingPassword: false,
            complete: false,
            password: "",
            passwordConfirm: "",
            passwordScore: 0,
            password1TooltipOpen: false,
            password1Message: "",
            password2TooltipOpen: false,
            password2Message: "",
            responseMessage: "",
            oldPassword: "",
            oldPasswordTooltipOpen: false,
            oldPasswordMessage: ""
        };

        this.onOldPasswordChange = this.onOldPasswordChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this);
        this.submitUpdatePassword = this.submitUpdatePassword.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
        this.onChangeScore = this.onChangeScore.bind(this);
    }

    onOldPasswordChange(e: any) {
        this.setState({
            oldPassword: e.target.value,
        });
    }

    onPasswordChange(e: any) {
        this.setState({
            password: e.target.value,
        });
    }

    onPasswordConfirmChange(e: any) {
        this.setState({
            passwordConfirm: e.target.value,
        });
    }

    submitUpdatePassword(e: any) {
        e.preventDefault();

        let error = false;

        if (this.state.oldPassword === "") {
            this.setState({
                oldPasswordMessage: "Please fill in this field.",
                oldPasswordTooltipOpen: true,
            });

            error = true;
        }

        if (this.state.passwordScore <= 1) {
            this.setState({
                password1Message: "Password not strong enough",
                password1TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.password === "") {
            this.setState({
                password1Message: "Please fill in this field.",
                password1TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.passwordConfirm === "") {
            this.setState({
                password2Message: "Please fill in this field.",
                password2TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.password !== this.state.passwordConfirm) {
            this.setState({
                password2Message: "Passwords do not match.",
                password2TooltipOpen: true,
            });

            error = true;
        }

        if (error) {
            return;
        }

        this.setState({
            updatingPassword: true
        });

        this.props.changePassword(this.state.oldPassword, this.state.password, this.handleResponse);
    }

    handleResponse(data: any) {
        if (data["success"]) {
            this.setState({
                complete: true,
                responseMessage: "Password updated"
            });
            return;
        }

        this.setState({
            complete: true,
            responseMessage: "Unable to update password at this time"
        });
    }

    onChangeScore(score: number) {
        this.setState({
            passwordScore: score
        });
    }

    renderUpdatePasswordForm() {
        return (
                <Row>
                    <Col md={{ size: 6, offset: 3 }}>
                        <div id="eform">
                            <form onSubmit={this.submitUpdatePassword} noValidate>
                                <label className="mt-2">Old Password</label>
                                <input name="password" type="password" className="form-control" id="password" onChange={this.onOldPasswordChange} />
                                <ValidationTooltip placement="bottom" tooltipOpen={this.state.oldPasswordTooltipOpen} target="password" text={this.state.oldPasswordMessage} />

                                <label className="mt-2">New Password</label>
                                <input name="password" type="password" className="form-control" id="password" onChange={this.onPasswordChange} />
                                <ValidationTooltip placement="bottom" tooltipOpen={this.state.password1TooltipOpen} target="password" text={this.state.password1Message} />
                                <PasswordStrengthBar shortScoreWord="Too short" scoreWords={["Too short", "Too weak", "Okay", "Good", "Strong"]} password={this.state.password} onChangeScore={this.onChangeScore} />

                                <label>Confirm Password</label>
                                <input name="password2" type="password" className="form-control" id="password2" onChange={this.onPasswordConfirmChange} />
                                <ValidationTooltip placement="bottom" tooltipOpen={this.state.password2TooltipOpen} target="password2" text={this.state.password2Message} />
                                <PasswordMatch password1={this.state.password} password2={this.state.passwordConfirm} />

                                <input type="submit" className="form-control" id="submit" value="Submit" />
                            </form>
                        </div>
                    </Col>
                </Row>
        );
    }

    renderUpdatingPassword() {
        return (
            <Row className="justify-content-md-center">
                <Col md={{ size: 'auto' }}>
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Updating...</span>
                    </Spinner>
                </Col>
            </Row>
        );
    }

    renderComplete() {
        return (
            <Row className="justify-content-md-center">
                <Col md={{ size: 'auto' }}>
                    {this.state.responseMessage}
                </Col>
            </Row>
        );
    }

    renderContent() {
        if (this.state.complete) {
            return this.renderComplete();
        }

        if (this.state.updatingPassword) {
            return this.renderUpdatingPassword();
        }

        return this.renderUpdatePasswordForm();
    }

    render() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Update Password</h2>
                        </Col>
                    </Row>
                    {this.renderContent()}
                </Container>
            </section>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(authActionCreators, dispatch);
};

export default connect(
    null,
    mapDispatchToProps
)(ChangePassword);
