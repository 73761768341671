import * as React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as AuthStore from '../../store/AuthStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../services/AuthService';
import { ValidationTooltip } from '../validation_tooltip/ValidationTooltip';
import PasswordStrengthBar from 'react-password-strength-bar';
import { PasswordMatch } from '../password_match/PasswordMatch';

interface IState {
    password: string,
    passwordConfirm: string,
    code: string,
    userId: string,
    updating: boolean,
    complete: boolean,
    passwordScore: number,
    password1TooltipOpen: boolean,
    password1Message: string,
    password2TooltipOpen: boolean,
    password2Message: string,
    responseMessage: string
}

type AuthProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof authActionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>;


class ResetPassword extends React.PureComponent<AuthProps, IState> {
    static displayName = ResetPassword.name;

    constructor(props: AuthProps) {
        super(props);
        this.state = {
            password: "",
            passwordConfirm: "",
            code: "",
            userId: "",
            updating: false,
            complete: false,
            passwordScore: 0,
            password1TooltipOpen: false,
            password1Message: "",
            password2TooltipOpen: false,
            password2Message: "",
            responseMessage: ""
        };

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this);
        this.onChangeScore = this.onChangeScore.bind(this);
        this.submitResetPassword = this.submitResetPassword.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const userId = params.get('userId');
        const code = params.get('code');

        this.setState({
            code: code!,
            userId: userId!
        })
    }

    onPasswordChange(e: any) {
        this.setState({
            password: e.target.value,
            password1TooltipOpen: false
        });
    }

    onPasswordConfirmChange(e: any) {
        this.setState({
            passwordConfirm: e.target.value,
            password2TooltipOpen: false
        });
    }

    submitResetPassword(e: any) {
        e.preventDefault();

        let error = false;

        if (this.state.passwordScore <= 1) {
            this.setState({
                password1Message: "Password not strong enough",
                password1TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.password === "") {
            this.setState({
                password1Message: "Please fill in this field.",
                password1TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.passwordConfirm === "") {
            this.setState({
                password2Message: "Please fill in this field.",
                password2TooltipOpen: true,
            });

            error = true;
        }

        if (this.state.password !== this.state.passwordConfirm) {
            this.setState({
                password2Message: "Passwords do not match.",
                password2TooltipOpen: true,
            });

            error = true;
        }

        if (error) {
            return;
        }

        this.setState({
            updating: true
        });

        this.props.resetPassword(this.state.password, this.state.code, this.state.userId, this.handleResponse);
    }

    handleResponse(data: any) {
        if (data["success"]) {
            //Get user info
            this.setState({
                complete: true,
                responseMessage: "Password updated, please login"
            });
            return;
        }

        this.setState({
            complete: true,
            responseMessage: "Unable to update password at this time"
        });
    }

    onChangeScore(score: number) {
        this.setState({
            passwordScore: score
        });
    }

    renderResetPasswordForm() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Enter your email address</h2>
                        </Col>
                    </Row>
                    <div>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <div id="eform">
                                    <form onSubmit={this.submitResetPassword} noValidate>
                                        <label className="mt-2">New Password</label>
                                        <input name="password" type="password" className="form-control" id="password" onChange={this.onPasswordChange} />
                                        <ValidationTooltip placement="bottom" tooltipOpen={this.state.password1TooltipOpen} target="password" text={this.state.password1Message} />
                                        <PasswordStrengthBar shortScoreWord="Too short" scoreWords={["Too short", "Too weak", "Okay", "Good", "Strong"]} password={this.state.password} onChangeScore={this.onChangeScore} />

                                        <label>Confirm Password</label>
                                        <input name="password2" type="password" className="form-control" id="password2" onChange={this.onPasswordConfirmChange} />
                                        <ValidationTooltip placement="bottom" tooltipOpen={this.state.password2TooltipOpen} target="password2" text={this.state.password2Message} />
                                        <PasswordMatch password1={this.state.password} password2={this.state.passwordConfirm} />

                                        <input type="submit" className="form-control" id="submit" value="Submit" />
                                    </form>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Link to="/login">Login</Link>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Link to="/create-account">Create account</Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        );
    }

    renderUpdating() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Sending email</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={{ size: 'auto' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Sending...</span>
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    renderComplete() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>{this.state.responseMessage}</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Link to="/login">Login</Link>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Link to="/create-account">Create account</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    render() {
        if (this.state.complete) {
            return this.renderComplete();
        }

        if (this.state.updating) {
            return this.renderUpdating();
        }

        return this.renderResetPasswordForm();
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(authActionCreators, dispatch);
};

export default connect(
    null,
    mapDispatchToProps
)(ResetPassword);
