import * as React from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import { Layout } from './components/layouts/Layout';
import { DefaultLayout } from './components/layouts/DefaultLayout';
import Login from './components/login/LoginPage';
import { CreateAccount } from './components/create_account/CreateAccount';
import Home from './components/home/Home';
import './css/templatemo-style.css';
import './css/custom.css'
import './custom.css'
import PrivateRoute from './components/routes/PrivateRoute';
import PublicRoute from './components/routes/PublicRoute';
import Logout from './components/logout/LogoutPage';
import { ConfirmEmail } from './components/confirm_email/ConfirmEmail';
import ForgotPassword from './components/forgot_password/ForgotPassword';
import ResetPassword from './components/reset_password/ResetPassword';
import ChangePassword from './components/change_password/ChangePassword';
import PrivacyPolicy from './components/privacy_policy/PrivacyPolicy';
import DeleteAccount from './components/delete_account/DeleteAccount';
import { Admin } from './components/admin/Admin';
import { Unauthorized } from './components/unauthorized/Unauthorized';

export default () => (
    <Switch>
        <Route path={["/login-oidc"]}>
            <Layout>
                <Switch>
                    <PublicRoute path="/login-oidc" component={Login} />
                    <PublicRoute path="/create-account" component={CreateAccount} />
                </Switch>
            </Layout>
        </Route>
        {/* Layout 1 is last because it is used for the root "/" and will be greedy */}
        <Route path={["/create-account", "/login", "/logout", "/confirm-email", "/"]}>
            <DefaultLayout>
                <Switch>
                    <Route path='/confirm-email' component={ConfirmEmail} />
                    <Route path='/privacy-policy' component={PrivacyPolicy}/>
                    <PublicRoute path="/create-account" component={CreateAccount} />
                    <PublicRoute path="/forgot-password" component={ForgotPassword} />
                    <PublicRoute path="/reset-password" component={ResetPassword} />
                    <PrivateRoute path="/change-password" component={ChangePassword} />
                    <PublicRoute path="/login" component={Login} />
                    <PrivateRoute path="/logout" component={Logout} />
                    <PrivateRoute path="/delete-account" component={DeleteAccount} />
                    <PrivateRoute path="/admin" roles={["SuperAdmin", "Admin"]} component={Admin} />
                    <PrivateRoute path="/" component={Home} />
                    {/*<PrivateRoute path='/' component={() => {*/}
                    {/*    window.location.href = 'https://datadyne.webclient:5001/account';*/}
                    {/*    return null;*/}
                    {/*}} />*/}
                </Switch>
            </DefaultLayout>
        </Route>    
    </Switch>
);
