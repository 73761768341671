import * as React from 'react';
import NavMenu from '../NavMenu';
import { Footer } from "../footer/Footer";

export class DefaultLayout extends React.Component {
    static displayName = DefaultLayout.name;

    render() {
        return (
            <div>
                <NavMenu />
                {this.props.children}
                <Footer />
            </div>
        );
    }
}
