import * as React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as AuthStore from '../../store/AuthStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../services/AuthService';
import { ValidationTooltip } from '../validation_tooltip/ValidationTooltip';

interface IState {
    email: string,
    sendingEmail: boolean,
    complete: boolean,
    emailTooltipOpen: boolean,
    emailTooltipMessage: string
}

type AuthProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof authActionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>;


class ForgotPassword extends React.PureComponent<AuthProps, IState> {
    static displayName = ForgotPassword.name;

    constructor(props: AuthProps) {
        super(props);
        this.state = {
            email: "",
            sendingEmail: false,
            complete: false,
            emailTooltipOpen: false,
            emailTooltipMessage: ""
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.submitForgotPassword = this.submitForgotPassword.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    onEmailChange(e: any) {
        this.setState({
            email: e.target.value,
            emailTooltipOpen: false,
        });
    }

    submitForgotPassword(e: any) {
        e.preventDefault();

        let error = false;

        if (this.state.email === "") {
            this.setState({
                emailTooltipMessage: "Please fill in this field.",
                emailTooltipOpen: true,
            });

            error = true;
        }

        if (error) {
            return;
        }

        this.setState({
            sendingEmail: true
        });

        this.props.sendForgotPasswordEmail(this.state.email, this.handleResponse);
    }

    handleResponse(data: any) {
        this.setState({
            complete: true
        });
    }

    renderForgotpasswordForm() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Enter your email address</h2>
                        </Col>
                    </Row>
                    <div>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <div id="eform">
                                    <form onSubmit={this.submitForgotPassword} noValidate>

                                        <label>Email</label>
                                        <input name="email" type="text" className="form-control" id="email" onChange={this.onEmailChange} />
                                        <ValidationTooltip placement="bottom" tooltipOpen={this.state.emailTooltipOpen} target="email" text={this.state.emailTooltipMessage} />

                                        <input type="submit" className="form-control" id="submit" value="Submit" />
                                    </form>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Link to="/login">Login</Link>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Link to="/create-account">Create account</Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        );
    }

    renderSendingEmail() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Sending email</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={{ size: 'auto' }}>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Sending...</span>
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    renderComplete() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>A password reset link has been sent to the email address.</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Link to="/login">Login</Link>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <Link to="/create-account">Create account</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    render() {
        if (this.state.complete) {
            return this.renderComplete();
        }

        if (this.state.sendingEmail) {
            return this.renderSendingEmail();
        }

        return this.renderForgotpasswordForm();
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(authActionCreators, dispatch);
};

export default connect(
    null,
    mapDispatchToProps
)(ForgotPassword);
