import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import * as AuthStore from '../../store/AuthStore';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';

interface CustomPrivateRouteProps extends RouteProps {
    roles?: string[]
}

type PrivateRouteProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof AuthStore.actionCreators // ... plus action creators we've requested
    & CustomPrivateRouteProps;

interface IState {
    isAuthenticated: boolean
}

class PrivateRoute extends React.PureComponent<PrivateRouteProps, IState> {
    static displayName = PrivateRoute.name;
    
    constructor(props: PrivateRouteProps) {
        super(props);

        this.state = {
            isAuthenticated: this.props.isAuthenticated
        };
    }

    redirectUrl = "/login";

    render() {
        if (this.state.isAuthenticated) {

            if (this.props.roles) {
                if (this.props.roles.some(r => this.props.userRoles.includes(r))) {
                    return <Route {...this.props} />;
                }
                else {
                    return <Redirect to={"/unauthorized"} />;
                }
            }

            return <Route {...this.props} />;
        }
        else {
            return <Redirect to={this.redirectUrl} />;
        }
    }
}

export default connect(
    (state: ApplicationState) => state.authStore,
    AuthStore.actionCreators
)(PrivateRoute);