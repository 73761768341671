import * as React from 'react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as AuthStore from '../../store/AuthStore';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActionCreators from '../../services/AuthService';

interface IState {
    email: string,
    password: string,
    errorMsg: string,
    signingIn: boolean
}

type AuthProps =
    AuthStore.AuthState // ... state we've requested from the Redux store
    & typeof authActionCreators // ... plus action creators we've requested
    & RouteComponentProps<{}>;

class Login extends React.PureComponent<AuthProps, IState> {
    static displayName = Login.name;

    constructor(props: AuthProps) {
        super(props);

        this.state = {
            email: "",
            password: "",
            errorMsg: "",
            signingIn: false
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    onEmailChange(e: any) {
        this.setState({
            email: e.target.value,
        });
    }

    onPasswordChange(e: any) {
        this.setState({
            password: e.target.value,
        });
    }

    submitLogin(e: any) {
        e.preventDefault();
        this.setState({
            signingIn: true
        });
        this.props.loginUser(this.state.email, this.state.password, this.handleResponse);
    }

    handleResponse(data: any) {
        if (!data["success"]) {
            switch (data["errorCode"]) {
                case 4:
                    this.setState({
                        errorMsg: "Email or password is incorrect",
                    });
                    break;
                default:
                    this.setState({
                        errorMsg: "Something went wrong, please try again",
                    });
                    break;
            }

            this.setState({
                signingIn: false
            });
        }
        else {
            const redirectUrl: string = data["result"]["RedirectUrl"];
            window.location.href = redirectUrl;
        }
    }

    renderLoginForm() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2>Enter your login details</h2>
                        </Col>
                    </Row>
                    <div>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <div id="eform">
                                    <form onSubmit={this.submitLogin} noValidate>

                                        <label>Username</label>
                                        <input name="username" type="text" className="form-control" id="username" onChange={this.onEmailChange} />

                                        <label>Password</label>
                                        <input name="password" type="password" className="form-control" id="password" onChange={this.onPasswordChange} />

                                        <p style={{ color: '#ED4337', textAlign: 'center' }}>{this.state.errorMsg ? this.state.errorMsg : ""}</p>
                                        <input type="submit" className="form-control" id="submit" value="Login" />
                                    </form>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Link to="/forgot-password">Forgot your password?</Link>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Col md="auto">
                                <Link to={{ pathname: '/create-account', search: this.props.location.search }}>Create account</Link>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        );
    }

    renderSigningIn() {
        return (
            <section id="contact">
                <Container>
                    <Row>
                        <Col md="12">
                            <h2 className="wow bounceIn" data-wow-offset="50" data-wow-delay="0.3s">Logging in...</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={{ size: 'auto' }}>
                            <Spinner animation="border" role="status">
                                Signing in...
                            </Spinner>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

    render() {
        return this.state.signingIn ? this.renderSigningIn() : this.renderLoginForm();
    }
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(authActionCreators, dispatch);
};

export default connect(
    null,
    mapDispatchToProps
)(Login);